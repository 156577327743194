import React from 'react'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import styled from 'styled-components'
import TypeTag from '../blocks/TypeTag'


const CategorisedQA = ({category, question, answerHtml}) => {
	return (
		<Accordion disableGutters style={{ boxShadow: 'none', borderBottom: '1px solid rgb(245 245 245)' }}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel2a-content"
				style={{ padding: '0', marginBottom: '8px' }}
			>
				<Header>
					<TypeTag item={category} background={"rgb(244 245 246)"}>
						<div className='date'>
							{question}
						</div>
					</TypeTag>
				</Header>
			</AccordionSummary>
			<AccordionDetails style={{ padding: '0', margin: '0' }}>
				<Content dangerouslySetInnerHTML={{ __html: answerHtml }} />
			</AccordionDetails>
		</Accordion>
	)
}

const Header = styled.h2`
	font-size: 22px; 
	padding: 0; 
	margin: 18px 0 10px 0; 
	color: rgb(38 75 101);
	.date {
		margin-bottom: 0px;
		@media screen and (max-width: 991px) {
		};

	}
`

const Content = styled.div`
	font-size: 16px; 
	color: rgb(38 75 101); 
	width: 1020px;
	@media screen and (max-width: 991px) {
		width: 100%; 
		a {
			word-break: break-all;
		}
	};
	p {
		font-size: 18px !important; 
		text-align: left !important;
	}
`
export default CategorisedQA
