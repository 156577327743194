export const help = (faqs) => {
  const getData = (faqs) => {
    const mainEntity = []
    faqs.forEach((faq, index) => {
      mainEntity.push(
        {
          "@type": "Question", 
          "name": `${faq.question}`,
          "acceptedAnswer": {
            "@type": "Answer",
            "text": `${faq.answer.childMarkdownRemark.html}`
          }
        }
      )
    })
    return mainEntity
  }
  return {
    "@context": "https://schema.org/", 
    "@type": "FAQPage", 
    "mainEntity": getData(faqs)
  }
}