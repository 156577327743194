import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Seo from '../../components/seo'
import Layout from '../../layouts/layout'
import BlueHeader from '../../components/UI/Header/BlueHeader'
import FaqComponent from '../../components/ForFaqPage/faqComponent'
import { help } from '../../support/structuredDataConfig/marketplace/help'
import { CONTACT } from '../../support'

const FaqPage = ({ path }) => {

  const data = useStaticQuery(graphql`
  query FaqComponentQuerySpendSave {
    allContentfulComponentFaQs
    (filter: {category: {in: [ "Apple Pay", "Google Pay", "Debit Cards", "Fees and Charges", "Closing an account", "Payments and Transactions", "Savings Rules", "Sending and receiving payments", "Making a Complaint", "SpendOne and SaveOne accounts are closing"] }}) 
    {
      nodes {
        category
        question
        answer {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`)

const faqs = data.allContentfulComponentFaQs.nodes

  return (
    <Layout hasGetYourRate={false}>
      <Seo 
        title="Frequently Asked Questions"
        description="Helpful answers to your questions about personal loans and rates, credit scores, investing and more."
        path={path}
        structuredData={help(faqs)}
      />
      <BlueHeader headerText={"Frequently Asked Questions"}>
      <p>Can’t find what you’re looking for? Contact us at <a className='alink' href={`mailto:${CONTACT.EMAIL.CUSTOMER_SERVICE}`}>{CONTACT.EMAIL.CUSTOMER_SERVICE}</a></p>
      </BlueHeader>
      <FaqComponent faqs={faqs} />
    </Layout>

  )
}

export default FaqPage
