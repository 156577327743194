export const reOrderTags = (tag, arr) => {
	// put the tag at the second of the array
	const newArr = arr.filter(item => item !== tag)
	newArr.splice(1, 0, tag)
	return newArr
}

// re-order the faqs by category
export const reOrderArrByCategory = (tagArr, faqs) => {
	const newArr = []
	tagArr.forEach(tag => {
		const filtered = faqs.filter(item => item.category === tag)
		newArr.push(...filtered)
	})
	return newArr
}