import React, { useCallback, useState } from 'react'
import { useFlexSearch } from 'react-use-flexsearch'
import { useStaticQuery, graphql } from 'gatsby'

import ContentContainer from '../UI/ContentContainer'
import styled from 'styled-components'
import CategorisedQA from './categorisedQA'
import FilterButton from '../UI/Buttons/FilterButton'
import { useResetState } from '../../utils/blogRelated'
import { reOrderTags, reOrderArrByCategory } from '../../utils/faqs'

const FaqComponent = ({ faqs }) => {

	const data = useStaticQuery(graphql`
		query FaqsSearchQuery {
			localSearchFaqsSearch {
				index
				store
			}
		}
	`)


  // this part is for the filter
	const originCategories = ["All", ...new Set(faqs.map(item => item.category))]; // Get unique categories
	// re-order the originCategories
	const categories = reOrderTags("SpendOne and SaveOne accounts are closing", originCategories)

	let initState = {};
	categories.forEach((key) => {
		initState[key] = false
	});
	const [typeState, setTypeState] = useState('All')

	// customized hooks to reset the state
	const [btnState, setBtnState, resetBtnState] = useResetState(initState)
	const handleClick = useCallback((type) => {
		resetBtnState()
		setBtnState({
			[type]: true
		})
		setTypeState(type)
	}, [setBtnState, setTypeState, resetBtnState])
	const reOrderFaqs = reOrderArrByCategory(categories, faqs)
	const filteredFaqs = reOrderFaqs.filter((item) => typeState === "All" || item.category === typeState);



	// this part is for search bar
  const index = data.localSearchFaqsSearch.index
  const store = data.localSearchFaqsSearch.store
  const handleChange = (e) => setInputState(e.target.value)
  const [inputState, setInputState] = useState('')
  const searchedResults = useFlexSearch(inputState, index, store)
  const filteredSearchedResults = searchedResults.filter((item) => typeState === "All" || item.category === typeState)
  

	return (
		<ContentWrapper>
			<div className='section grey-bg'>
				<SearchAndFilters>
					<SearchBar>
						<Input>
							<input id='searchInFaqs' type='text' placeholder='Search...' value={inputState} onChange={handleChange}/>
						</Input>
					</SearchBar>
					<Filter>
						<div style={{marginLeft: 5}}>Filter By:</div>
						<Buttons>
							{categories.map((item, index) => {
								return (
									<FilterButton key={index} isClicked={btnState[item]} onClick={() => handleClick(item)}>
										{item}
									</FilterButton>
								)
							})}
						</Buttons>
					</Filter>
				</SearchAndFilters>
			</div>
			<ContentContainer padding={"60px 0 20px 0"}>
				{inputState === '' ?
					filteredFaqs.map((item, index) => <CategorisedQA key={index} category={item.category} question={item.question} answerHtml={item.answer.childMarkdownRemark.html} />)
					:
					filteredSearchedResults.map((item, index) => <CategorisedQA key={index} category={item.category} question={item.question} answerHtml={item.answer} />)
				}
			</ContentContainer>
		</ContentWrapper>
	)
}

const ContentWrapper = styled.div`

	.section {
		position: relative;
		overflow: hidden;
		padding-right: 30px;
		padding-left: 30px;
	}
	.section.grey-bg {
		background-color: #f4f5f6;
	}
`

const SearchAndFilters = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin: 20px 0;

	position: relative;
	width: 100%;
	max-width: 1232px;
	margin-right: auto;
	margin-left: auto;

	@media screen and (max-width: 768px) {
		flex-direction: column;
		align-items: start;
	}
`
const SearchBar = styled.div`
  width: 30%;
  @media screen and (max-width: 768px) {
	width:100%; 
	margin-bottom: 10px; 
}
`
const Filter = styled.div`
	width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	font-size: 14px;
	letter-spacing: 0;
	@media screen and (max-width: 768px) {
		width:100%;  
	}

`
const Buttons = styled.div`
	
`
const Input = styled.div`
  display: flex;
  ${'' /* flex-direction: column; */}
  justify-content: center;
  align-items: flex-start;
  width:100%;  
  input {
    width: 100%;
    height: 46px;
    padding: 4px 24px;
    border-radius: 30px;
    border: 1px solid rgb(225 225 225);
    font-size: 18px;
  }
`

export default FaqComponent
